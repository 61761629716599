import React from "react";
import "./OpeningDoor.css";
import BlobBackground from "../BlobBackground/BlobBackground";

function OpeningDoor() {
  return (
    <div className="opening-door">
      {/* <img src="https://source.unsplash.com/mou0S7ViElQ" alt="minion" /> */}
      <div className="no-overflow">
        <BlobBackground></BlobBackground>
      </div>
      <div id="left-door" className="door">
        <div className="shape shape-up"></div>
        <div className="shape shape-down"></div>
        <div id="left-knob" className="knob"></div>
      </div>
      <div id="right-door" className="door">
        <div className="shape shape-up"></div>
        <div className="shape shape-down"></div>
        <div id="right-knob" className="knob"></div>
      </div>
    </div>
  );
}

export default OpeningDoor;
