import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { ThemeContext } from "../../App";
import React from "react";
import OpeningDoor from "Components/organisms/OpeningDoor/OpeningDoor";

export default function Body() {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useContext(ThemeContext);
  const themeClassName = theme + "-mode";

  // const elementsToShuffle = [1, 2, 3, 4];
  return (
    <div className={`body ${themeClassName}`}>
      <div className="center">
        <OpeningDoor></OpeningDoor>
      </div>
      <h1>RJAOUEN</h1>
      <h2>Front-end developper</h2>

      {/* Liste ordonnable
      <ReorderList></ReorderList> */}

      {/* A animer avec une modification de la disposition des cases */}
      <section id="grid">
        <div id="main-grid">
          <div id="grid-column-one">
            <p>Développement</p>
          </div>
          <div id="grid-column-two">
            <p>Design</p>
          </div>
          <div id="grid-column-three">
            <p>Spécifications</p>
          </div>
          <div id="grid-column-four">
            <p>Tests</p>
          </div>
        </div>
      </section>
      <motion.button
        id="shuffle-button"
        onClick={() => setIsVisible(!isVisible)}
        whileHover={{
          scale: 1.5,
          backgroundColor: "rgba(139, 218, 207, 0.58)",
        }}
        whileTap={{ scale: 2, backgroundColor: "rgba(0, 221, 190, 0.72)" }}
        // whileDrag={{ scale: 3, background: "red" }}
        // whileFocus={{ scale: 2, background: "blue" }}
      >
        Mélanger
      </motion.button>
    </div>
  );
}
