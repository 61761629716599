import "./DarkModeToggle.css";
import React, { useContext } from "react";
import { ThemeContext } from "../../App";

export function DarkModeToggle() {
  const themeContext = ThemeContext;

  const { theme, setTheme } = useContext(themeContext);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={theme === "dark"}
        onChange={() => setTheme(theme === "dark" ? "light" : "dark")}
      />
      <span className="slider"></span>
      {theme === "dark" && <span className="span-moon">🌙</span>}
      {theme === "light" && <span className="span-sun">🔆</span>}
    </label>
  );
}
