import "./App.css";
import Header from "./Components/templates/Header.jsx";
import Body from "./Components/templates/Body";
import React, { createContext, useState } from "react";

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState("dark");
  const className = `${theme}-mode`;

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <div className={`App ${className}`}>
        <Header></Header>
        <Body></Body>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
