import { DarkModeToggle } from "../atoms/DarkModeToggle";
import "./Header.css";
import React, { useContext } from "react";
import { ThemeContext } from "../../App";

export default function Header() {
  const themeContext = ThemeContext;
  const { theme } = useContext(themeContext);

  const className = `${theme}-mode`;

  return (
    <header className={`app-header ${className}`}>
      <ul className="menu">
        <li>Home</li>
        <li>Who am I</li>
        <li>Projects</li>
      </ul>
      <div className="toggle-container">
        <DarkModeToggle></DarkModeToggle>
      </div>
      {/* <div>&#9881;</div> */}
    </header>
  );
}
